import { Card } from '../Card'
import { LabelValue } from '../../../../shared/labelValue'
import { StarRating } from '../../../../shared/starRating'
import { FooterText } from '../../../../shared/footerText'
import { Exception } from './exception'
import ContentBox from '../../../../shared/ContentBox'
import { useQualityPageContext } from '../../../../../contextproviders/QualityPageContext'
import { filterByMainCategory } from '../../../../../helper/filters'
import { TRAFFIC_LIGHT_THRESHOLDS } from '../../../../../helper/constants'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const Premises = ({ category }) => {
  const { t } = useTranslation()
  const {
    quality: { data },
  } = useQualityPageContext()
  const [uiData, setUiData] = useState({})
  const thresholds = TRAFFIC_LIGHT_THRESHOLDS[category]
  useEffect(() => {
    const filteredReports = filterByMainCategory(data, category)
    const reportsData = {
      totalRating: 0,
      total: 0,
      green: 0,
      yellow: 0,
      red: 0,
    }
    filteredReports.forEach((d) => {
      const reportDate = moment(d.date)
      const now = new Date()
      const isThisYear = reportDate.isSameOrAfter(moment(now).startOf('year'))
      if (!isThisYear) {
        return
      }

      reportsData.total++
      reportsData.totalRating += d.rating
      d.isWarning && reportsData.yellow++
      d.isError && reportsData.red++
      !d.isWarning && !d.isError && reportsData.green++
    })
    setUiData(reportsData)
  }, [data, category])

  const showCard = !!uiData.total

  return showCard ? (
    <ContentBox>
      <Card titleId={category}>
        <LabelValue label="Tänä vuonna" value={``} />
        <StarRating large rating={uiData.totalRating / uiData.total} />
        <FooterText>
          {uiData.total} {t('reports')}
        </FooterText>
        <LabelValue label="POIKKEAMAT" value="" />
        <Exception
          isWarning
          amount={uiData.yellow}
          from={thresholds.yellow}
          to={thresholds.green}
        />
        <Exception
          isError
          amount={uiData.red}
          from={0}
          to={thresholds.yellow}
        />
      </Card>
    </ContentBox>
  ) : (
    <></>
  )
}
