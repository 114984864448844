import moment from 'moment'

export function getMinDate (dates = []) {
  if (dates.length > 0) {
    // Set 1 month previous to actual min, so that min month can be selected
    const min = moment(Math.min(...dates)).subtract(1, 'M')
    return new Date(min)
  } else return new Date()
}

export function getMaxDate (dates = []) {
  if (dates.length > 0) {
    const max = Math.max(...dates)
    return new Date(max)
  } else return new Date()
}

export function filterByDateRange (data = [], startDate, endDate, dateKey = 'date') {
  if (!startDate || !endDate) {
    return data
  }

  // Add 1 month to endDate because we want to include that month in the date range
  const actEndDate = moment(endDate).add(1, 'M')
  return data.filter(row => {
    // if date value is null, the row it belongs to is not filtered out
    if (row[dateKey] === null) {
      return true
    }
    return moment(row[dateKey]).isSameOrAfter(startDate) && moment(row[dateKey]).isBefore(actEndDate)
  })
}

function filterByStartDate (data = [], startDate, dateKey = 'date') {
  return data.filter(row => {
    // if date value is null, the row it belongs to is not filtered out
    if (row[dateKey] === null) {
      return true
    }
    return moment(row[dateKey]).isSameOrAfter(startDate)
  })
}

export function filterByTwoDateRanges (
  data = [],
  primaryStartDate,
  primaryEndDate,
  primaryDateKey = 'date',
  secondaryStartDate,
  secondaryEndDate,
  secondaryDateKey = 'date') {
  let filteredData

  // always filter first based on primaryDate
  if (!primaryEndDate) {
    filteredData = filterByStartDate(data, primaryStartDate, primaryDateKey)
  } else {
    filteredData = filterByDateRange(data, primaryStartDate, primaryEndDate, primaryDateKey)
  }

  if (!secondaryEndDate) {
    return filterByStartDate(filteredData, secondaryStartDate, secondaryDateKey)
  } else {
    return filterByDateRange(filteredData, secondaryStartDate, secondaryEndDate, secondaryDateKey)
  }
}

export function transformDateToValueOf (data, dateKey = 'date') {
  if (!data) {
    return []
  }

  return data.map(d => {
    d[dateKey] = d[dateKey] ? d[dateKey].valueOf() : d[dateKey]
    return d
  })
}

export function filterByMonthYear (data, date) {
  if (!date) {
    return data
  }

  return data.filter(row => {
    return moment(row.date).isSame(date, 'year') && moment(row.date).isSame(date, 'month')
  })
}

export function getMonthLabelsWithCapitalLetters () {
  const labels = moment.monthsShort()
  return labels.map(month => month.charAt(0).toLocaleUpperCase() + month.slice(1))
}
