import React, { useEffect, useState } from 'react'
import { useTable, useSortBy, useFilters } from 'react-table'

import { useTranslation } from 'react-i18next'
import { getRowsForTable, increaseDisplayedTableRows } from '../../helper'
import { ColumnFilter } from './ColumnFilter'

function SortableTable({
  columns,
  data,
  hasClickableRows = false,
  showBottomRowStats = true,
  getRowProps = () => ({}),
}) {
  const { t } = useTranslation()

  const defaultColumn = React.useMemo(
    () => ({
      Filter: ColumnFilter,
    }),
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useFilters,
      useSortBy
    )

  const [displayedRows, setDisplayedRows] = useState([])

  useEffect(() => {
    setDisplayedRows((d) => getRowsForTable(rows, d))
  }, [rows])

  const loadMoreRows = () => {
    setDisplayedRows(increaseDisplayedTableRows(rows, displayedRows))
  }

  const LoadMoreButton = () => {
    return displayedRows.length < rows.length ? (
      <button
        className="button is-primary is-outlined has-text-weight-bold"
        onClick={() => loadMoreRows()}
      >
        <span>{t('Load more')}</span>
      </button>
    ) : null
  }

  const getMessage = () => {
    if (rows.length <= 0) {
      return <p className="has-text-centered">{t('No results.')}</p>
    }

    if (displayedRows.length <= 0) {
      return (
        <p className="has-text-centered">
          {t('No results for selected period. Try another period setting.')}
        </p>
      )
    }
  }

  return (
    <>
      <table className="table is-hoverable is-fullwidth" {...getTableProps()}>
        <thead className="is-uppercase">
          {headerGroups.map((headerGroup, i) => (
            <tr key={i} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th key={i} className="is-unselectable">
                  {column.render('Header')}
                  {column.canSort ? (
                    <span
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span className="icon has-text-link">
                            <i className="fa fa-sort-down" />
                          </span>
                        ) : (
                          <span className="icon has-text-link">
                            <i className="fa fa-sort-up" />
                          </span>
                        )
                      ) : (
                        <span className="icon">
                          <i className="fa fa-sort" />
                        </span>
                      )}
                    </span>
                  ) : null}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {displayedRows.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                key={i}
                {...row.getRowProps(getRowProps(row))}
                className={hasClickableRows ? 'is-clickable' : ''}
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td key={i} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      {getMessage()}

      <div className="buttons is-centered">{LoadMoreButton()}</div>
      {showBottomRowStats ? (
        <>
          <br />
          <p>
            {t('Showing the first {a} results of {b} rows', {
              a: displayedRows.length,
              b: rows.length,
            })}
          </p>
        </>
      ) : null}
    </>
  )
}

export default SortableTable
