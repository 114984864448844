import React, { useEffect, useState } from 'react'
import {
  getAPIClient,
  getAuthClient,
  getDataFromResponse,
} from '../helper/axios.client'
import { OPTION_ALL, OPTION_EMPTY } from '../helper/constants'
import { errorFlag } from '../helper/messages'
import i18n from 'i18next'

export const AppContext = React.createContext()

export const AppContextProvider = ({ authContext, children }) => {
  const apiClient = getAPIClient('customers')
  const authClient = getAuthClient()

  const [customers, setCustomers] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [sites, setSites] = useState([])
  const [selectedSiteId, setSelectedSiteId] = useState(OPTION_EMPTY)
  const [isFetchingData, setIsFetchingData] = useState(false)

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (selectedCustomer) {
      setSites(selectedCustomer.sites)
      setSelectedSiteId(OPTION_ALL)
    }
  }, [selectedCustomer])

  const init = async () => {
    // On initial load: fetch user profile, then customers and sites
    setIsFetchingData(true)
    try {
      const result = await authClient.get('/profile')
      const user = result.data.user

      if (result.status === 200 && result.data) {
        authContext.setCurrentUser(user)
      }
      await fetchCustomers()
    } catch (e) {
      console.error('ERROR', e)
    }
    setIsFetchingData(false)
  }

  const fetchCustomers = async () => {
    try {
      const response = await apiClient.get('')
      const data = getDataFromResponse(response.data.data, 'customers')
      setCustomers(data)

      // First customer from array as initial selection
      const initialCustomer = data[0]
      setSelectedCustomer(initialCustomer)
    } catch (e) {
      console.error('ERROR', e)
      errorFlag(i18n.t('Error fetching data'))
    }
  }

  const changeSelectedCustomer = (customerId) => {
    const customer = customers.find((cus) => cus.id === customerId)
    setSelectedCustomer(customer)
  }

  const values = {
    customers,
    selectedCustomer,
    changeSelectedCustomer,
    sites,
    selectedSiteId,
    setSelectedSiteId,
    isFetchingData,
  }

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  return React.useContext(AppContext)
}
