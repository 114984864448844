import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppContext } from '../../contextproviders/AppContext'
import { OPTION_ALL } from '../../helper/constants'
import { groupToLabel } from '../../helper/react-select'
import Select from '../shared/form/Select'
import SiteSelect from '../shared/form/SiteSelect'
import Tag from '../shared/Tag'

function CustomerAndSiteSelection() {
  const { t } = useTranslation()
  const {
    customers,
    selectedCustomer,
    changeSelectedCustomer,
    sites,
    selectedSiteId,
    setSelectedSiteId,
  } = useAppContext()

  const [selectedSites, setSelectedSites] = useState([])
  const [siteOptions, setSiteOptions] = useState([])
  const [filterText, setFilterText] = useState('')

  useEffect(() => {
    setSiteOptions(groupToLabel(sites, 'city', 'name', 'id'))
  }, [sites])

  const onCustomerChange = (customer) => {
    changeSelectedCustomer(customer.value)
    // clear selected sites when customer changes
    setSelectedSiteId(OPTION_ALL)
    setSelectedSites([])
  }

  const onSiteChange = (selectSites) => {
    if (selectSites.length === 0) {
      setSelectedSites([])
      setSiteOptions((prevState) =>
        prevState.map((city) => {
          return { ...city, isChecked: false }
        })
      )
      setFilterText(filterText)
    } else {
      setSelectedSites(selectSites)
      setFilterText(filterText)
    }
  }

  const handleFilterTextChange = (value) => {
    setFilterText(value)
  }

  const onApplySitesClick = () => {
    if (selectedSites.length === 0) {
      setSelectedSiteId(OPTION_ALL)
    } else {
      setSelectedSiteId(selectedSites.map((site) => site.value).join(','))
    }
  }

  const optionContainsString = (option, filterString) => {
    if (
      option.label.toLowerCase().includes(filterString.toLowerCase()) ||
      option.siteDetails.street
        .toLowerCase()
        .includes(filterString.toLowerCase()) ||
      option.siteDetails.postcode.includes(filterString) ||
      option.siteDetails.city.toLowerCase().includes(filterString.toLowerCase())
    ) {
      return true
    }

    return false
  }

  const onSelectAllClick = () => {
    if (!filterText) {
      const options = []
      siteOptions.forEach((city) => {
        city.options.forEach((site) => options.push(site))
      })
      setSelectedSites(options)
    } else {
      // find all options that match filter string
      const filterString = filterText

      const filteredOptions = []
      siteOptions.forEach((city) => {
        city.options.forEach((site) => {
          if (optionContainsString(site, filterString)) {
            filteredOptions.push(site)
          }
        })
      })

      setSelectedSites(filteredOptions)
    }
  }

  const onRemoveAllClick = () => {
    setSelectedSites([])
    setSiteOptions((prevState) =>
      prevState.map((city) => {
        return { ...city, isChecked: false }
      })
    )
  }

  const onSelectCityClick = (_event, label, isChecked, index) => {
    const newSiteOptions = [...siteOptions]
    newSiteOptions[index].isChecked = !isChecked

    const filteredOptions = []
    newSiteOptions.forEach((city) => {
      if (city.isChecked) {
        city.options.forEach((site) => filteredOptions.push(site))
      }
    })

    setSiteOptions(newSiteOptions)
    setSelectedSites(filteredOptions)
  }

  const formatGroupLabel = ({ label, isChecked, index }) => {
    return (
      <div className="is-flex is-align-items-center">
        <input
          className="mr-2"
          checked={isChecked}
          onChange={(e) => onSelectCityClick(e, label, isChecked, index)}
          type="checkbox"
        />
        <span>{label}</span>
      </div>
    )
  }

  const formatOptionLabel = ({ value, label, siteDetails }) => {
    return (
      <div className="is-flex is-justify-content-space-between">
        <div className="is-flex is-align-items-center has-text-weight-bold">
          {label}
        </div>
        <div className="is-flex is-align-items-center is-size-6">
          {`${siteDetails.street || ''} 
          ${siteDetails.house_number || ''}, 
          ${siteDetails.postcode || ''}
          ${siteDetails.city || ''}`}
        </div>
      </div>
    )
  }

  const filterOption = (option, string) => {
    setFilterText(string)

    if (optionContainsString(option.data, string)) {
      return true
    }
    return false
  }

  const getTagLabel = (siteIds) => {
    const currentlyActiveSites = siteIds.split(',')
    if (currentlyActiveSites[0] === 'ALL') {
      return t('All sites selected')
    }

    const label =
      currentlyActiveSites.length.toString() +
      ' ' +
      (currentlyActiveSites.length === 1
        ? t('Site selected')
        : t('Sites selected'))
    return label
  }

  const getSiteSelect = () => {
    if (sites && sites.length > 0) {
      sites.sort((a, b) =>
        a?.name?.localeCompare(b?.name, 'fi', {
          sensitivity: 'base',
          numeric: true,
        })
      )
      return (
        <>
          <SiteSelect
            placeholder={t('Select sites')}
            value={selectedSites}
            onChange={onSiteChange}
            onClick={onApplySitesClick}
            options={siteOptions}
            formatOptionLabel={formatOptionLabel}
            formatGroupLabel={formatGroupLabel}
            filterText={filterText}
            onTextChange={handleFilterTextChange}
            id="site-select"
            className="is-fullwidth"
            isFilterActive={filterText && filterText.length > 0}
            filterOption={filterOption}
            onSelectAllClick={onSelectAllClick}
            onRemoveAllClick={onRemoveAllClick}
            onCityClick={onSelectCityClick}
            menuBoxWidth="800px"
            menuBoxHeight="600px"
          />
          <Tag label={getTagLabel(selectedSiteId)} maxWidth="218px" />
        </>
      )
    } else {
      return (
        <Select
          id="site-select"
          className="is-fullwidth"
          disabled
          label={t('Sites not found')}
          options={[{ label: t('Sites not found') }]}
        />
      )
    }
  }

  const getCustomerSelect = () => {
    if (customers && customers !== 'undefined' && customers.length > 1) {
      customers.sort((a, b) => a?.name?.localeCompare(b?.name))
      return (
        <Select
          defaultValue={customers[0]}
          placeholder={t('Select customer')}
          onChange={onCustomerChange}
          options={[
            ...customers.map((cus) => {
              return { label: cus.name, value: cus.id }
            }),
          ]}
          label={customers.length > 1 ? getSelectedCustomerLabel() : ''}
          id="customer-select"
          className="is-fullwidth"
        />
      )
    } else {
      return (
        <Select
          id="customer-select"
          className="is-fullwidth"
          label={t('Customers not found')}
          disabled
          options={[{ label: t('Customers not found') }]}
        />
      )
    }
  }

  const getSelectedCustomerLabel = () => {
    return selectedCustomer ? (
      selectedCustomer.name
    ) : (
      <span className="has-text-danger">{t('Customer not found')}</span>
    )
  }

  return (
    <div className="pl-4 pb-4 pr-4">
      <div className="field">
        <div className="control">
          {customers.length === 1 ? (
            <div id="customer-label" className="label">
              {selectedCustomer?.name}
            </div>
          ) : (
            getCustomerSelect()
          )}
        </div>
      </div>

      <div className="field">
        <div className="control">{getSiteSelect()}</div>
      </div>
    </div>
  )
}

export default CustomerAndSiteSelection
