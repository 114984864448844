import { Doughnut } from 'react-chartjs-2'
import React from 'react'

function DoughnutChart ({ data, options }) {
  return (
    <Doughnut data={data} options={options} />
  )
}

export default DoughnutChart
