export const YearSelection = ({
  years,
  chosenYear,
  setChosenYear,
  style = {},
}) => {
  return (
    <div style={{ ...style, display: 'flex', flexDirection: 'row' }}>
      {years.length > 0
        ? years.sort().map((year) => (
            <div
              key={year}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'flex-end',
                fontSize: '1rem',
                padding: '0.15rem 0.75rem',
                margin: '1rem 0.5rem',
                borderBottom: chosenYear === year ? '4px solid #003087' : '',
                marginBottom:
                  chosenYear === year ? '0.75rem' : 'calc(0.75rem + 4px)',
                color: chosenYear === year ? null : 'hsla(0, 0%, 50%, 1)',
              }}
              onClick={() => setChosenYear(year)}
            >
              <b>{year}</b>
            </div>
          ))
        : null}
    </div>
  )
}
