import './exteption.css'
import { Star } from '../../../../shared/starRating/star'

export const Exception = ({
  amount = 1,
  from = 0,
  to = 4,
  isWarning = false,
  isError = false,
}) => {
  return (
    <div className="exception-wrapper">
      <span>{amount} x</span>
      <Star small warning={isWarning} error={isError} />
      <span>
        Havainnot {from?.toFixed(2)} – {to?.toFixed(2)}
      </span>
    </div>
  )
}
