import React, { useEffect, useState } from 'react'
import useQuality from '../hooks/useQuality'
import useQualitySummary from '../hooks/useQualitySummary'
import { OPTION_ALL } from '../helper/constants'

export const QualityPageContext = React.createContext()

export const QualityPageContextProvider = ({ appContext, children }) => {
  const { selectedCustomer, selectedSiteId } = appContext
  const quality = useQuality(selectedCustomer?.id, selectedSiteId, selectedCustomer?.level)
  //const qualitySummary = useQualitySummary(selectedCustomer?.id, selectedSiteId)
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  useEffect(() => {
    if (selectedCustomer && selectedSiteId) {
      fetchQualityData()
    }
    setIsInitialLoad(false)
  }, [])

  useEffect(() => {
    // When selected site is changed -> fetch data from REST API
    if (!isInitialLoad && selectedCustomer && selectedSiteId) {
      fetchQualityData()
    }
  }, [selectedSiteId])

  useEffect(() => {
    // Only fetch quality data again when selected customer has changed and selected site equals OPTION_ALL
    // this will only trigger when selectedSiteId equals OPTION_ALL before and after selected customer change
    // because selected customer change precedes selected site change
    if (!isInitialLoad && selectedCustomer && selectedSiteId === OPTION_ALL) {
      fetchQualityData()
    }
  }, [selectedCustomer])

  const fetchQualityData = async () => {
    setIsFetchingData(true)
    try {
      await quality.reload()
     // await qualitySummary.reload()
    } catch (e) {
      console.error(e)
    }
    setIsFetchingData(false)
  }

  const values = {
    quality,
    //qualitySummary,
    isFetchingData,
  }

  return (
    <QualityPageContext.Provider value={values}>
      {children}
    </QualityPageContext.Provider>
  )
}

export const useQualityPageContext = () => {
  return React.useContext(QualityPageContext)
}
