import './barStyles.css'

export const Bar = ({ navy, blue, lightBlue, tiny = false }) => {
  return (
    <div className={`bar-wrapper${tiny ? ' tiny' : ''}`}>
      <div className="bar-navy" style={{ width: `${navy}%` }} />
      <div className="bar-blue" style={{ width: `${blue}%` }} />
      <div className="bar-lightBlue" style={{ width: `${lightBlue}%` }} />
    </div>
  )
}
