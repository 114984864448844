import { Bar } from "react-chartjs-2";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  Chart as ChartJS,
} from "chart.js";
import React from "react";
import { defaultOptions } from "../../../helper/charts/charts.options";

ChartJS.register(ArcElement, CategoryScale, Legend, LinearScale, BarElement);

function VerticalBarChart({ data, options = defaultOptions }) {
  return (
    <div className="vertical-bar-chart">
      <Bar data={data} options={options} />
    </div>
  );
}

export default VerticalBarChart;
