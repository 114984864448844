import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import AppBrand from '../shared/AppBrand'
import { useAuthContext } from '../../contextproviders/AuthContext'
import CustomerAndSiteSelection from './CustomerAndSiteSelection'

function NavMenu() {
  const { t } = useTranslation()

  return (
    <nav className="menu" role="navigation" aria-label="main navigation">
      <div className="brand-wrapper p-4">
        <AppBrand />
      </div>

      <CustomerAndSiteSelection />

      <ul className="menu-list mt-4">
        <li>
          <NavLink to="/app/quality">{t('Quality')}</NavLink>
        </li>

        <li>
          <NavLink to="/app/tasks">{t('Tasks')}</NavLink>
        </li>

        <li>
          <NavLink to="/app/costs">{t('Costs')}</NavLink>
        </li>

        <li>
          <NavLink to="/app/carbon-footprint">{t('Carbon footprint')}</NavLink>
        </li>
      </ul>
    </nav>
  )
}

export function SubMenu() {
  const { t } = useTranslation()
  const { currentUser, LOGOUT_URL } = useAuthContext()

  return (
    <ul className="menu-list bottom-list">
      <li>
        <span className="menu-label current-user">
          {/*TODO remove when using IAM*/}
          {/* {currentUser ? currentUser.username : t('No user found')} */}

          {/*TODO uncomment when using IAM*/}
          {currentUser ? currentUser.name : t('No user found')}
        </span>
      </li>
      <li>
        <a className="has-text-primary" href={LOGOUT_URL}>
          <span className="icon">
            <i className="fa fa-sign-out" />
          </span>
          {t('Logout')}
        </a>
      </li>
    </ul>
  )
}

export default NavMenu
