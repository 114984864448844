export const CLEANING_SERVICES = 'Siivous'
export const MAINTENANCE_SERVICES = 'Kiinteistöhuolto'

export const costToCarbonConversionRates = {
  [CLEANING_SERVICES]: {
    2024: 0.05,
    2023: 0.05,
    2022: 0.06,
  },
  [MAINTENANCE_SERVICES]: {
    2024: 0.2,
    2023: 0.2,
    2022: 0.22,
  },
}

export const emissionToScopeConversionRates = {
  [CLEANING_SERVICES]: {
    2024: {
      0: 0.0555,
      1: 0,
      2: 0.9445,
    },
    2023: {
      0: 0.0555,
      1: 0,
      2: 0.9445,
    },
    2022: {
      0: 0.0555,
      1: 0,
      2: 0.9445,
    },
  },
  [MAINTENANCE_SERVICES]: {
    2024: {
      0: 0.1224,
      1: 0.0002,
      2: 0.8774,
    },
    2023: {
      0: 0.1224,
      1: 0.0002,
      2: 0.8774,
    },
    2022: {
      0: 0.1224,
      1: 0.0002,
      2: 0.8774,
    },
  },
}

export const getCostsByYearAndService = (costs, year, service) =>
  costs.filter((cost) => cost.year === year && cost.service === service)

export const getTotalSum = (costs) =>
  costs.reduce((acc, obj) => acc + obj.costs.reduce((i, j) => i + j.cost, 0), 0)

export const convertCostToEmissions = (cost, year, service) => {
  const conversionRate = costToCarbonConversionRates[service][year]
  return cost * conversionRate
}

export const localeNumberWithDecimals = (number, decimals) =>
  number.toFixed(decimals).toString().replace('.', ',')
