import { Card } from '../Card'
import { LabelValue } from '../../../../shared/labelValue'
import { LargeNumber } from '../../../../shared/largeNumber'
import ContentBox from '../../../../shared/ContentBox'
import moment from 'moment/moment'
import { useQualityPageContext } from '../../../../../contextproviders/QualityPageContext'
import { useEffect, useState } from 'react'
import { filterByCategory } from '../../../../../helper/filters'
import { CATEGORIES } from '../../../../../helper/constants'

export const Maintenance = () => {
  const {
    quality: { data },
  } = useQualityPageContext()
  const [reports, setReports] = useState({})
  useEffect(() => {
    const maintenance = filterByCategory(data, CATEGORIES[6])
    const others = filterByCategory(data, CATEGORIES[7])
    const newReportsData = {
      thisYear: 0,
      lastYear: 0,
    }
    const now = new Date()

    ;[...maintenance, ...others].forEach((r) => {
      const reportDate = moment(r.date)
      reportDate.isSameOrAfter(moment(now).startOf('year')) &&
        newReportsData.thisYear++
      reportDate.isBetween(
        moment(now).subtract(1, 'year').startOf('year'),
        moment(now).subtract(1, 'year').endOf('year')
      ) && newReportsData.lastYear++
    })
    setReports(newReportsData)
  }, [data])

  const showCard = !!reports.thisYear || !!reports.lastYear

  return showCard ? (
    <ContentBox>
      <Card titleId="Maintenance reports">
        <LabelValue label="Tänä vuonna" value={``} />
        <LargeNumber number={reports.thisYear} />
        <LabelValue label="Viime vuonna" value="" />
        <LargeNumber number={reports.lastYear} />
      </Card>
    </ContentBox>
  ) : null
}
