function CarbonFootprintTextContent2022() {
  return (
    <div className="content" style={{ width: '70%' }}>
      <h3 className="title ">Palveluiden hiilijalanjälki</h3>
      <p>
        Hiilijalanjälki mittaa yrityksen toiminnasta ja arvoketjusta suoraan tai
        epäsuorasti ilmakehään vapautuvien kasvihuonekaasujen (GHG)
        kokonaismäärää, joka yleensä ilmaistaan hiilidioksidiekvivalentteina
        (CO2e). L&T:n hiilijalanjälkiseuranta käsittää koko sen arvoketjun eli
        oman toiminnan lisäksi myös toimitusketjun päästöt. Laskenta perustuu
        the Greenhouse Gas Protocol - standardiin.
      </p>
      <p>
        L&T:n kiinteistöpalveluiden hiilijalanjälki koostuu oman toiminnan ja
        arvoketjun päästöistä. Siivous- ja tukipalveluiden osalta
        kokonaispäästömäärä vuodelta 2023 oli 7 544 tCO2e ja kiinteistöjen
        ylläpitopalveluiden osalta 20 791 tCO2e. Asiakaskohtainen
        CO2-päästömäärä lasketaan asiakkuuden liikevaihdon summalla ja
        päästökertoimella. Vuoden <strong>2022</strong> päästökertoimet ovat
      </p>
      <ul>
        <li>
          siivous- ja tukipalveluiden osalta <strong>0,0558 kg CO2/€</strong> ja
        </li>
        <li>
          ylläpitopalveluiden, eli huollon ja kiinteistötekniikan osalta{' '}
          <strong>0,2179 kg CO2/€</strong>.
        </li>
      </ul>
      <p>
        Päästölaskenta päättyneelle kalenterivuodelle tehdään vuosittain
        helmikuun lopussa.
      </p>
      <h4>Hiilijalanjälkilaskentamme perusta</h4>
      <p>
        Laskennassa on huomioitu suorat scope 1 ja 2 -päästöt sekä scope 3
        osalta kategoria 1 - ostetut tuotteet ja palvelut. L&T:n oman toiminnan
        osuus hiilijalanjäljestä on vähäinen. Päästöt syntyvät pääosin
        ajoneuvojen ja työkoneiden polttoaineen kulutuksesta. Lisäksi päästöjä
        syntyy kiinteistöjen energian hankinnasta.
      </p>
      <p>
        Kulutettu polttoaineen määrä lasketaan CO2e-päästöksi Tilastokeskuksen
        polttoaineluokituksen<sup>(1</sup> päästökertoimilla, joissa on
        huomioitu polttoaineiden sisältämät bio-osuudet Suomessa. Energian
        päästölaskenta perustuu kulutusseurantaan ja markkinapohjaisiin
        päästökertoimiin. Suomessa käytämme kiinteistöissämme päästötöntä,
        ydinvoimalla tuotettua sähköä. Valtaosa hiilijalanjäljestämme syntyy
        toimitusketjussa, jolloin päästölähteenä ovat ostetut tuotteet ja
        palvelut. Toimitusketjun päästölaskenta perustuu ostodataan ja
        europohjaisiin päästökertoimiin<sup>(2</sup>.
      </p>
      <p>
        Siivouksen ja kiinteistöjen ylläpitopalveluiden aiheuttama
        kokonaispäästömäärä on jaettu kunkin palvelun liikevaihdolla, jonka
        seurauksena on saatu palvelukohtainen CO2-päästökerroin kgCO2/€.
        Laskenta perustuu konsernin päästölaskentaan, joka on ulkopuolisen
        osapuolen varmentama. Palvelun lopullinen päästölaskenta ei ole
        ulkopuolisen osapuolen varmentama.
      </p>
      <p>
        Kehitämme jatkuvasti toimintaamme päästöjen laskennan osalta.
        Osallistuimme syksyllä 2023 kiinteistöjen ylläpidon
        CO2-laskentahankkeeseen, jossa pilotoitiin yhteisiä käytäntöjä ja
        laskentamenetelmiä tarkemmalle kohdekohtaiselle CO2 päästölaskennalle.
        Tämä auttoi meitä tunnistamaan kehityskohteita datan keräämisessä ja
        hallinnassa, jotta päästäisiin käyttämään tarkempaa menetelmää.
      </p>
      <h4>L&T:n päästövähennystavoitteet</h4>
      <p>
        L&T on asettanut tavoitteita, jotka ovat SBTi-aloitteen mukaisia.
        Tavoitteemme on
      </p>
      <ul>
        <li>
          puolittaa oman toimintamme hiilijalanjälki-intensiteetti (tnCO2/km)
          vuoteen 2030 mennessä (2018),
        </li>
        <li>
          vähentää alihankinnasta syntyviä kuljetus- ja työkonepäästöjä 30 %
          vuoteen 2030 mennessä (2020), sekä
        </li>
        <li>
          saavuttaa nettonolla vuoteen 2045 loppuun mennessä. Tavoite koskee
          yrityksen koko arvoketjua.
        </li>
      </ul>
      <p>
        L&T pyrkii pienentämään oman toimintansa hiilijalanjälkeä parantamalla
        kiinteistöjen energiatehokkuutta, sekä korvaamalla kaluston fossiilisia
        polttoaineita päästöttömillä vaihtoehdoilla. Suomessa käytämme
        kiinteistöissämme päästötöntä, ydinvoimalla tuotettua sähköä.
      </p>
      <h5>Lähteet</h5>
      <p>
        1){' '}
        <a href="https://stat.fi/tup/khkinv/khkaasut_polttoaineluokitus.html">
          https://stat.fi/tup/khkinv/khkaasut_polttoaineluokitus.html
        </a>
      </p>
      <p>
        2) Suomen Ympäristökeskuksen raportteja 2019 - Julkisten hankintojen ja
        kotitalouksien kulutuksen hiilijalanjälki ja luonnonvarojen käyttö.
        ENVIMAT-mallinnuksen tuloksia{' '}
        <a
          href="https://helda.helsinki.fi/bitstream/handle/10138/300737/SYKEra_15_2019_korjattu_26_02_2020.pdf?
  sequence=4&isAllowed=y"
        >
           https://helda.helsinki.fi/bitstream/handle/10138/300737/SYKEra_15_2019_korjattu_26_02_2020.pdf?
          sequence=4&isAllowed=y
        </a>
      </p>
    </div>
  )
}

export default CarbonFootprintTextContent2022
