import { useTranslation } from 'react-i18next'
import './styles.css'

export const Card = ({ titleId, children }) => {
  const { t } = useTranslation()

  return (
    <div>
      <span className="card-title">{t(titleId)}</span>
      {children}
    </div>
  )
}
