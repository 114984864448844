import { Slide, toast } from 'react-toastify'

const options = {
  position: 'top-right',
  icon: true,
  autoClose: false,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  transition: Slide,
  className: 'notification p-2 is-light is-danger'
}

const template = (message) => {
  return (
    <span>{message}</span>
  )
}

const errorFlag = (message) =>
  toast.error(template(message), options)

export { errorFlag }
