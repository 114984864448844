import React from 'react'
import ContentBox from '../../shared/ContentBox'
import QualityTable from './QualityTable'
import {
  QualityPageContextProvider,
  useQualityPageContext,
} from '../../../contextproviders/QualityPageContext'
import { useAppContext } from '../../../contextproviders/AppContext'
import LoadableContent from '../../shared/LoadableContent'
import { PageTitle } from '../../shared/pageTitle'
import { SurfaceHygiene } from './cards/hygiene'
import { Premises } from './cards/premises'
import { Maintenance } from './cards/maintenance'
import './styles.css'
import { MAIN_CATEGORIES } from '../../../helper/constants'
import QualityTrackingTable from './QualityTrackingTable'

function QualityPage() {
  const appContext = useAppContext()

  return (
    <QualityPageContextProvider appContext={appContext}>
      <QualityPageContent />
    </QualityPageContextProvider>
  )
}

function QualityPageContent() {
  const { isFetchingData } = useQualityPageContext()
  return (
    <LoadableContent isLoading={isFetchingData}>
      <PageTitle titleId="Quality" />
      <div className="cards-wrapper">
        <SurfaceHygiene />
        <Premises category={MAIN_CATEGORIES[1]} />
        <Premises category={MAIN_CATEGORIES[2]} />
        <Premises category={MAIN_CATEGORIES[3]} />
        <Premises category={MAIN_CATEGORIES[4]} />
        <Maintenance />
      </div>
      <ContentBox>
        <QualityTrackingTable />
      </ContentBox>
      <ContentBox>
        <QualityTable />
      </ContentBox>
    </LoadableContent>
  )
}

export default QualityPage
