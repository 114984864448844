
import { useState } from 'react'
import Rselect, { components } from 'react-select'
import { useTranslation } from 'react-i18next'

function SiteSelect ({
  label = '',
  selected,
  value,
  onChange,
  onClick,
  options,
  formatOptionLabel,
  formatGroupLabel,
  placeholder = '',
  className = '',
  id = '',
  disabled = false,
  filterText,
  onTextChange,
  isFilterActive,
  filterOption,
  onSelectAllClick,
  onRemoveAllClick,
  onCityClick,
  menuBoxWidth = '',
  menuBoxHeight = ''
}) {
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState(false)

  const closeMenu = () => {
    setMenuOpen(false)
    onClick()
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#003087'
    }),
    clearIndicator: base => ({
      ...base,
      display: 'none'
    }),
    menu: base => ({
      ...base,
      width: menuBoxWidth || base.width
    }),
    menuList: base => ({
      ...base,
      width: menuBoxWidth || base.width
    }),
    option: (styles, { data, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? '#003087'
          : undefined,
        color: isSelected
          ? '#ffffff'
          : '#000000',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected
            ? '#003087'
            : '#99b0db',
          color: isSelected
            ? '#ffffff'
            : '#000000'
        }
      }
    }
  }

  const handleApplyClick = () => {
    onClick()
    closeMenu()
  }

  const handleOpen = () => {
    setMenuOpen(true)
  }

  const getTotalOptionsCount = () => {
    let totalCount = 0
    options.forEach(city => {
      totalCount += city.options.length
    })

    return totalCount
  }

  const getOptionsCount = () => {
    const totalCountAsString = getTotalOptionsCount().toString()
    const selectedCountAsString = value.length.toString()

    return (
      <div className='mr-3 is-size-6'>
        {t('chosen') + ' ' + selectedCountAsString + '/' + totalCountAsString}
      </div>
    )
  }

  const Menu = ({ children, ...props }) => (
    <components.Menu {...props}>
      <div className='m-2 p-1 is-flex is-justify-content-space-between'>
        <div className='is-flex is-align-items-flex-start'>
          <button
            className='button is-primary is-outlined mr-1'
            onClick={onSelectAllClick}
          >
            {t('Select all')}
          </button>
          {value.length > 0 &&
            <button
              className='button is-ghost has-text-danger'
              onClick={onRemoveAllClick}
            >
              {t('Empty selection')}
            </button>}
        </div>
        <div className='is-flex is-align-items-center is-justify-content-flex-end'>
          {getOptionsCount()}
          <div>
            <button onClick={handleApplyClick} className='button is-primary'>
              {t('Apply selection')}
            </button>
          </div>
        </div>
      </div>
      {children}
    </components.Menu>
  )

  return (
    <div className='field'>
      {
        label && <label className='label'>{label}</label>
      }
      <div className='control'>
        <Rselect
          placeholder={menuOpen ? t('Enter search term') : placeholder}
          value={value}
          isMulti
          filterOption={filterOption}
          inputValue={filterText}
          onInputChange={onTextChange}
          options={options}
          formatGroupLabel={formatGroupLabel}
          formatOptionLabel={formatOptionLabel}
          onChange={onChange}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          closeMenuOnSelect={false}
          blurInputOnSelect={false}
          backspaceRemovesValue={false}
          menuIsOpen={menuOpen}
          onMenuOpen={handleOpen}
          onMenuClose={closeMenu}
          styles={customStyles}
          maxMenuHeight={menuBoxHeight}
          components={{ Menu }}
        />
      </div>
    </div>
  )
}

export default SiteSelect
