import NavMenu, { SubMenu } from './navigation/NavMenu'
import AppPageRoutes from './AppPageRoutes'
import {
  AppContextProvider,
  useAppContext,
} from '../contextproviders/AppContext'
import { useAuthContext } from '../contextproviders/AuthContext'
import LoadableContent from './shared/LoadableContent'
import { ToastContainer } from 'react-toastify'

function AppMain() {
  const authContext = useAuthContext()

  return (
    <AppContextProvider authContext={authContext}>
      <div className="App">
        {authContext.currentUser && <AppMainContent />}
        <ToastContainer closeButton={false} />
      </div>
    </AppContextProvider>
  )
}

function AppMainContent() {
  const { isFetchingData } = useAppContext()

  return (
    <LoadableContent isLoading={isFetchingData} withAppBrand>
      <header className="App-header box p-0 pb-5">
        <NavMenu />
        <SubMenu />
      </header>

      <div className="App-main">
        <main className="container is-fluid">
          <AppPageRoutes />
        </main>
      </div>
    </LoadableContent>
  )
}

export default AppMain
