import { forwardRef } from 'react'
import LTDatePicker from './LTDatePicker'
import LTYearPicker from './LTYearPicker'

function YearPicker ({ date, startDate, endDate, dateOnChange, minDate, maxDate, renderCustomHeader }) {
  const DateInput = forwardRef(({ value, onClick }, ref) => (
    <button className='button is-ghost has-text-primary has-icons-left' onClick={onClick} ref={ref}>
      <span className='icon'><i className='fa fa-calendar' /></span>
      <span>{value}</span>
    </button>
  ))

  return (

    <div className='month-year-picker field has-addons is-align-items-center'>

      <div className='control'>
        <LTYearPicker
          selected={date}
          onChange={dateOnChange}
          minDate={minDate}
          maxDate={maxDate}
          startDate={startDate}
          endDate={endDate}
          renderCustomHeader={renderCustomHeader}
          customInput={<DateInput />}
        />
      </div>
    </div>
  )
}

export default YearPicker
