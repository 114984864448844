import { forwardRef } from 'react'
import LTDatePicker from './LTDatePicker'

function MonthYearRangePicker ({ startDate, startDateOnChange, endDate, endDateOnChange, minDate, maxDate }) {
  const StartDateInput = forwardRef(({ value, onClick }, ref) => (
    <button className='button is-ghost has-text-primary has-icons-left' onClick={onClick} ref={ref}>
      <span className='icon'><i className='fa fa-calendar' /></span>
      <span>{value}</span>
    </button>
  ))

  const EndDateInput = forwardRef(({ value, onClick }, ref) => (
    <button className='button is-ghost has-text-primary' onClick={onClick} ref={ref}>
      <span>{value}</span>
    </button>
  ))

  return (

    <div className='month-year-range field has-addons is-align-items-center'>

      <div className='control'>
        <LTDatePicker
          selected={startDate}
          onChange={startDateOnChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={<StartDateInput />}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>

      <div className='control'>
        <span className='icon'><i className='fa fa-minus' /></span>
      </div>

      <div className='control'>
        <LTDatePicker
          selected={endDate}
          onChange={endDateOnChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          customInput={<EndDateInput />}
          minDate={startDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  )
}

export default MonthYearRangePicker
