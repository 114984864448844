import { Star } from "./star";
import "./styles.css";

export const StarRating = ({ rating, maxRating = 4, large = false }) => {
  return (
    <div className={`star-rating${large ? " large" : ""}`}>
      <span>{rating?.toFixed(1)}</span>
      <span>/{maxRating}</span>
      <Star warning={rating >= 1.5 && rating <= 2.75} error={rating < 1.5} />
    </div>
  );
};
