import React, { useState } from 'react'
import { AUTH_PROXY } from '../config'

export const AuthContext = React.createContext()

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)
  const LOGIN_URL = `${AUTH_PROXY}/auth/login`
  const LOGOUT_URL = `${AUTH_PROXY}/auth/logout`

  const values = {
    currentUser,
    setCurrentUser,
    LOGIN_URL,
    LOGOUT_URL,
  }

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  return React.useContext(AuthContext)
}
