import DatePicker from 'react-datepicker'
import fi from 'date-fns/locale/fi'

function LTDatePicker (props) {
  return (
    <DatePicker
      locale={fi}
      className='select'
      dateFormat='MMM yyyy'
      showMonthYearPicker
      calendarClassName='lt-calendar'
      {...props}
    />
  )
}

export default LTDatePicker
