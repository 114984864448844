import AppBrand from '../../shared/AppBrand'
import { useTranslation } from 'react-i18next'
import LoginForm from './LoginForm'

function LoginPage() {
  const { t } = useTranslation()

  return (
    <div className="App-login is-flex is-flex-direction-column is-justify-content-space-between">
      <section className="section is-medium">
        <div className="container is-max-widescreen">
          <div className="columns">
            <div className="column is-one-third">
              <AppBrand />
              <h1 className="title mt-6">{t('Log in')}</h1>
              <LoginForm />
            </div>
          </div>
        </div>
      </section>

      <footer className="p-5">
        <div className="container is-max-desktop">
          <ul className="footer-links is-flex is-justify-content-space-evenly">
            <li>
              <a href="https://www.lt.fi/fi/tietosuojaseloste" target="_blank">
                {t('Privacy statement')}
              </a>
            </li>
            <li>
              <a href="https://www.lt.fi/fi/evasteet" target="_blank">
                {t('Cookies')}
              </a>
            </li>
          </ul>

          <ul className="footer-links is-flex is-justify-content-space-evenly mt-5">
            <li>
              <span title={t('Copyright Lassila & Tikanoja')}>
                <span className="icon">
                  <i className="fa fa-copyright" />
                </span>
                {t('Lassila & Tikanoja')}
              </span>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  )
}

export default LoginPage
