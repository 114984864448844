/**
 * Group generic array of objects to label for react-select.
 * @param {Array} generic array of objects
 * @param {string|number} group label
 * @param {string|number} option label
 * @param {string|number} option value
 * @returns {{label: string|number, options: {label: string|number, value: string|number}[]}[]}
 */
export const groupToLabel = (optionArr, groupLabelId, optionLabelId, optionValueId) => {
  const mapOptions = (arr) => arr.map((it) => ({ label: it[optionLabelId], value: it[optionValueId], siteDetails: it }))
  const groupReducer = (acc, cur, i, array) => {
    const groupLabelAndOptionLabelEqual = (it) => it[groupLabelId] === cur[groupLabelId]
    acc[cur[groupLabelId]] = { label: cur[groupLabelId], isChecked: false, options: mapOptions(array.filter(groupLabelAndOptionLabelEqual)) }
    return acc
  }
  const result = Object.values(optionArr.reduce(groupReducer, {}))
  result.forEach((res, i) => {
    res.index = i
  })
  return result
}
