export const DATE_FORMAT = 'DD.MM.yyyy'
export const DATETIME_FORMAT = 'DD.MM.yyyy HH.mm'

export const INIT_TABLE_ROW_COUNT = 20
export const TEST_DATA_COUNT = 55

export const OPTION_ALL = 'ALL'
export const OPTION_NONE = 'NONE'
export const OPTION_EMPTY = ''

export const DEFAULT_GREEN_RATING_THRESHOLD = 2.75
export const DEFAULT_RED_RATING_THRESHOLD = 1.5

export const MAIN_CATEGORIES = [
  'Pintahygienia',
  'Toimitilapalveluiden laatu',
  'Kaupan tukipalveluiden laatu',
  'Siivouspalveluiden laatu',
  'Huoltopalveluiden laatu',
  'Huoltoraportti',
  'Tekninen raportti',
]

export const CATEGORY_TO_MAIN_CATEGORY = {
  Pintahygienia: MAIN_CATEGORIES[0],
  Hygicult: MAIN_CATEGORIES[0],
  'Toimitilapalvelut Laatu': MAIN_CATEGORIES[1],
  'Kaupan tukipalvelun laatu': MAIN_CATEGORIES[2],
  'Siivouspalvelun laatu': MAIN_CATEGORIES[3],
  'Siivouspalveluiden laatu': MAIN_CATEGORIES[3],
  'Huoltopalveluiden laatu': MAIN_CATEGORIES[4],
  'Kiinteistön ylläpitopalveluiden laatu': MAIN_CATEGORIES[4],
  Huoltoraportti: MAIN_CATEGORIES[5],
  'Tekninen raportti': MAIN_CATEGORIES[6],
}

export const CATEGORIES = [
  'Pintahygienia',
  'Hygicult',
  'Toimitilapalvelut',
  'Kaupan tukipalvelu',
  'Siivouspalvelun laatu',
  'Kiinteistön ylläpitopalveluiden laatu',
  'Huoltoraportti',
  'KYP muut',
]

export const TRAFFIC_LIGHT_THRESHOLDS = {
  [MAIN_CATEGORIES[0]]: {
    reverse: true,
    yellow: 0,
    red: 0.2,
  },
  [MAIN_CATEGORIES[1]]: {
    green: 2.75,
    yellow: 1.5,
  },
  [MAIN_CATEGORIES[2]]: {
    green: 2.75,
    yellow: 1.5,
  },
  [MAIN_CATEGORIES[3]]: {
    green: 2.75,
    yellow: 1.5,
  },
  [MAIN_CATEGORIES[4]]: {
    green: 2.75,
    yellow: 1.5,
  },
}
