import { useEffect, useRef } from 'react'

const useDetectClickOutside = (ref, isOpen, handleClose) => {
  useEffect(() => {
    function handleClickOutside (event) {
      if (isOpen && ref.current && !ref.current.contains(event.target)) {
        handleClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, isOpen])
}

const LTModal = ({ isOpen, handleClose, subHeader = '', children }) => {
  const modalRef = useRef(null)
  useDetectClickOutside(modalRef, isOpen, handleClose)

  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        handleClose()
      }
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div className='modal-background' />
      <div className='modal-card'>
        <div ref={modalRef}>
          <header
            className={`modal-card-head is-radiusless has-background-white px-6 pt-6 pb-0  ${
              subHeader
                ? 'is-justify-content-space-between'
                : 'is-justify-content-flex-end'
            }`}
          >
            <h5 className='is-uppercase has-text-grey-light'>{subHeader}</h5>
            <button
              className='delete'
              aria-label='close'
              onClick={handleClose}
            />
          </header>
          <section className='modal-card-body px-6 pb-6 pt-0'>
            {children}
          </section>
        </div>
      </div>
    </div>
  )
}

export default LTModal
