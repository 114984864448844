import { TRAFFIC_LIGHT_THRESHOLDS } from './constants'
import moment from 'moment/moment'

export function filterByRating(data = [], shouldFilter = false) {
  if (!shouldFilter) {
    return data
  }

  return data.filter((i) => {
    let warning = false
    let error = false
    const category = i.reportType
    const threshold = TRAFFIC_LIGHT_THRESHOLDS[category]
    if (threshold && threshold.reverse) {
      warning = i.rating > threshold.yellow && i.rating <= threshold.red
      error = i.rating > threshold.red
    }
    if (threshold && !threshold.reverse) {
      warning = i.rating < threshold.green && i.rating >= threshold.yellow
      error = i.rating <= threshold.yellow
    }

    return warning || error
  })
}

export function filterByTwoDates(
  data = [],
  startDate,
  endDate,
  dateKey = 'date'
) {
  if (!startDate || !endDate) {
    return data
  }

  return data.filter((row) => {
    if (row[dateKey] === null) {
      return true
    }
    return (
      moment(row[dateKey]).isSameOrAfter(startDate) &&
      moment(row[dateKey]).isBefore(endDate)
    )
  })
}

export const filterByCategory = (data = [], category) =>
  data.filter((i) => i.reportType === category)

export const filterByMainCategory = (data = [], category) =>
  data.filter((i) => i.reportType === category)
