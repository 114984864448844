import React from 'react'

export const GlobalSearchFilter = ({ filter, setFilter, label, tooltipText = '' }) => {
  return (
    <>
      <div className='field'>
        {
          label && <label className='label'>{label}</label>
        }
        <div className={`control ${filter ? 'has-icons-right' : ''}`}>
          <input
            className='input'
            value={filter || ''}
            onChange={e => setFilter(e.target.value)}
          />
          {filter &&
            <span className='icon has-text-primary is-right is-large'>
              <i onClick={() => setFilter(undefined)} className='fa fa-times-circle fa-lg is-clickable' />
            </span>}
        </div>
      </div>
    </>
  )
}
