import { useState } from 'react'
import { getAPIClient, getDataFromResponse, getSitePathParam, convertFieldValueIfValueMatches } from '../helper/axios.client'
import { errorFlag } from '../helper/messages'
import i18n from 'i18next'

function useTasks (customerId = '', siteId = '', level = 2) {
  const client = getAPIClient('tasks/')
  const [data, setData] = useState([])

  const getTasks = async () => {
    const siteIdParam = getSitePathParam(siteId)
    return await client.get(`/${level}/${customerId}${siteIdParam}`)
  }

  // rename Siivous-service with more descriptive name
  const renameService = (data) => {
    return convertFieldValueIfValueMatches(
      data,
      'service',
      'Siivous',
      'Siivous- ja tukipalvelut'
    )
  }

  const reload = async () => {
    try {
      const response = await getTasks()
      const data = renameService(getDataFromResponse(response.data.data, 'tasks'))
      setData(data)
    } catch (e) {
      console.error(e)
      errorFlag(i18n.t('Error fetching data'))
    }
  }

  return { data, reload }
}

export default useTasks
