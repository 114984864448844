import Logo from '../../images/lt_logo.svg'
import { useTranslation } from 'react-i18next'

function AppBrand () {
  const { t } = useTranslation()

  return (
    <div className='App-brand is-flex is-align-items-center mb-3'>
      <figure className='image mr-2'>
        <img src={Logo} alt='L&T Logo' />
      </figure>
      <span className='App-name is-size-5 is-uppercase has-text-weight-semibold'>
        {t('APPLICATION_NAME')}
      </span>
    </div>
  )
}

export default AppBrand
