import { AuthContextProvider } from '../contextproviders/AuthContext'
import AppRoutes from './AppRoutes'
import 'font-awesome/css/font-awesome.min.css'
import '../styles/main.scss'

function App() {
  return (
    <AuthContextProvider>
      <div className="App-container">
        <AppRoutes />
      </div>
    </AuthContextProvider>
  )
}

export default App
