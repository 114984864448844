export const Star = ({ small = false, warning = false, error = false }) => {
  return (
    <svg
      width={small ? "18" : "36"}
      height={small ? "18" : "36"}
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M21.6304 1.01341C21.3241 0.392858 20.692 0 20 0C19.308 0 18.6759 0.392858 18.3696 1.01341L13.1741 11.539L1.55527 13.2373C0.870617 13.3373 0.30211 13.8173 0.0886963 14.4755C-0.124717 15.1337 0.0539602 15.856 0.549628 16.3388L8.95544 24.5261L6.97166 36.0926C6.85466 36.7747 7.1351 37.4641 7.69506 37.8709C8.25502 38.2777 8.99737 38.3313 9.60994 38.0091L20 32.5451L30.3901 38.0091C31.0026 38.3313 31.745 38.2777 32.3049 37.8709C32.8649 37.4641 33.1453 36.7747 33.0283 36.0926L31.0446 24.5261L39.4504 16.3388C39.946 15.856 40.1247 15.1337 39.9113 14.4755C39.6979 13.8173 39.1294 13.3373 38.4447 13.2373L26.8259 11.539L21.6304 1.01341Z"
        fill={warning ? "#FFD000" : error ? "#B31F1F" : "#279B31"}
      />
    </svg>
  );
};
