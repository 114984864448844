export const TrafficLight = ({ warning = false, error = false, gray = false }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_2633_11809)">
        <g clipPath="url(#clip1_2633_11809)">
          <path
            d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
            fill={warning ? "#FFD100" : error ? "#B31F1F" : gray ? "#6A6262" : "#3D7824"}
          />
          {(warning || error) && (
            <path
              d="M8.72701 5.0908C8.72701 4.68914 8.4014 4.36353 7.99973 4.36353C7.59807 4.36353 7.27246 4.68914 7.27246 5.0908V7.99989C7.27246 8.40155 7.59807 8.72716 7.99973 8.72716C8.4014 8.72716 8.72701 8.40155 8.72701 7.99989V5.0908ZM7.99973 10.1819C7.59807 10.1819 7.27246 10.5075 7.27246 10.9092C7.27246 11.3108 7.59807 11.6364 7.99973 11.6364H8.00701C8.40867 11.6364 8.73428 11.3108 8.73428 10.9092C8.73428 10.5075 8.40867 10.1819 8.00701 10.1819H7.99973Z"
              fillRule="evenodd"
              clipRule="evenodd"
              fill={warning ? "#1A1A1A" : "#FFFFFF"}
            />
          )}
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2633_11809">
          <rect width="16" height="16" fill="white" />
        </clipPath>
        <clipPath id="clip1_2633_11809">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
