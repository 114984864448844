import Rselect from 'react-select'
function Select ({
  label = '',
  selected,
  defaultValue,
  onChange,
  options,
  placeholder = '',
  className = '',
  id = '',
  isMulti = false,
  disabled = false,
  controlShouldRenderValue = true
}) {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '0px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: '#003087'
    })
  }

  return (
    <div className='field'>
      {
        label && <label className='label'>{label}</label>
      }
      <div className='control'>
        <Rselect
          styles={customStyles}
          placeholder={placeholder}
          defaultValue={defaultValue}
          options={options}
          onChange={onChange}
          isDisabled={disabled}
          isMulti={isMulti}
          controlShouldRenderValue={controlShouldRenderValue}
        />
      </div>
    </div>
  )
}

export default Select
