import { React } from 'react'

function Tag ({ label, title, handleDeleteClick, maxWidth = '' }) {
  return (
    <>
      <span
        style={{ maxWidth: maxWidth }}
        className='tag has-text-info has-background-primary-light is-medium'
      >
        <div
          style={{ maxWidth: '180px', overflow: 'hidden', textOverflow: 'ellipsis' }}
          title={title}
        >
          {label}
        </div>
        {handleDeleteClick &&
          <button
            onClick={handleDeleteClick}
            className='delete is-small'
          />}
      </span>
    </>
  )
}

export default Tag
