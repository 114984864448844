import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../../contextproviders/AuthContext'
// TODO uncomment when using IAM
import { useState } from 'react'
import { FORGOT_PASSWORD_URL, REACT_APP_BASE } from '../../../config'
// TODO end uncomment

function LoginForm() {
  // TODO uncomment when using IAM
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  // TODO end uncomment

  const { t } = useTranslation()
  const { LOGIN_URL } = useAuthContext()

  return (
    <div>
      {/*TODO remove when using IAM*/}
      {/* <div className='field'>
        <a
          href={LOGIN_URL}
          className='button is-link is-fullwidth'
          type='submit'
          form='login-form'
        >
          {t('Continue to login')}
        </a>
      </div> */}
      {/*TODO end remove*/}

      {/*TODO uncomment when using IAM*/}
      <form action={LOGIN_URL} method="POST">
        {new URLSearchParams(window.location.search).get('error') !== null && (
          <p className="login-error mb-2">{t('Incorrect credentials')}</p>
        )}
        <input
          name="username"
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          className="input mb-5 login-input"
          placeholder={t('Email')}
        />
        <input
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          type="password"
          className="input mb-2 login-input"
          placeholder={t('Password')}
        />
        <a href={FORGOT_PASSWORD_URL + REACT_APP_BASE} className="level-right">
          {t('Forgot password?')}
        </a>
        <input
          type="submit"
          value={t('Log in')}
          className="button is-link is-fullwidth mt-6"
        />
      </form>
      {/*TODO end uncomment*/}
    </div>
  )
}

export default LoginForm
