import { Navigate, Route, Routes } from 'react-router-dom'
import LoginPage from './pages/login/LoginPage'
import AppMain from './AppMain'

function AppRoutes() {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/app/*" element={<AppMain />} />
      <Route exact path="/" element={<Navigate to="app" />} />
    </Routes>
  )
}

export default AppRoutes
