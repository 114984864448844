import { Card } from '../Card'
import { Bar } from './bar'
import { LabelValue } from '../../../../shared/labelValue/index'
import { FooterText } from '../../../../shared/footerText'
import ContentBox from '../../../../shared/ContentBox'
import { useQualityPageContext } from '../../../../../contextproviders/QualityPageContext'
import { useEffect, useState } from 'react'
import moment from 'moment'
import { filterByMainCategory } from '../../../../../helper/filters'
import { MAIN_CATEGORIES } from '../../../../../helper/constants'
import { useTranslation } from 'react-i18next'

export const SurfaceHygiene = () => {
  const { t } = useTranslation()
  const {
    quality: { data },
  } = useQualityPageContext()
  const [hygieneReports, setHygieneReports] = useState(null)
  useEffect(() => {
    const filteredReports = filterByMainCategory(data, MAIN_CATEGORIES[0])
    if (!filteredReports.length) {
      setHygieneReports(null)
      return
    }
    const hygieneReportsData = {
      thisYear: {
        total: 0,
        green: 0,
        yellow: 0,
        red: 0,
      },
      lastYear: {
        total: 0,
        green: 0,
        yellow: 0,
        red: 0,
      },
    }

    const now = new Date()
    filteredReports.forEach((d) => {
      const reportDate = moment(d.date)
      const yearToIncrement = reportDate.isSameOrAfter(
        moment(now).startOf('year')
      )
        ? 'thisYear'
        : reportDate.isBetween(
            moment(now).subtract(1, 'year').startOf('year'),
            moment(now).subtract(1, 'year').endOf('year')
          )
        ? 'lastYear'
        : null
      if (!yearToIncrement) {
        return
      }

      hygieneReportsData[yearToIncrement].total++
      d.isWarning && hygieneReportsData[yearToIncrement].yellow++
      d.isError && hygieneReportsData[yearToIncrement].red++
      !d.isWarning && !d.isError && hygieneReportsData[yearToIncrement].green++
    })
    setHygieneReports(hygieneReportsData)
  }, [data])

  const showCard = !!hygieneReports
  return showCard ? (
    <ContentBox>
      {hygieneReports.thisYear && (
        <Card titleId="Surface hygiene">
          <LabelValue label="Tänä vuonna" value={``} />
          <Bar
            green={
              (hygieneReports.thisYear.green / hygieneReports.thisYear.total) *
              100
            }
            yellow={
              (hygieneReports.thisYear.yellow / hygieneReports.thisYear.total) *
              100
            }
            red={
              (hygieneReports.thisYear.red / hygieneReports.thisYear.total) *
              100
            }
          />
          <FooterText>
            {hygieneReports.thisYear.total} {t('reports')}
          </FooterText>
          <LabelValue label="Viime vuonna" value="" />
          {hygieneReports.lastYear.total > 0 ? (
            <Bar
              small
              green={
                (hygieneReports.lastYear.green /
                  hygieneReports.lastYear.total) *
                100
              }
              yellow={
                (hygieneReports.lastYear.yellow /
                  hygieneReports.lastYear.total) *
                100
              }
              red={
                (hygieneReports.lastYear.red / hygieneReports.lastYear.total) *
                100
              }
            />
          ) : (
            <br />
          )}

          <FooterText>
            {hygieneReports.lastYear.total} {t('reports')}
          </FooterText>
        </Card>
      )}
    </ContentBox>
  ) : null
}
