import { INIT_TABLE_ROW_COUNT } from './constants'

export function lorem () {
  return (
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ac tortor sem. Phasellus pellentesque justo ac blandit blandit. Mauris a justo vitae lorem suscipit tincidunt. Aliquam lacinia vehicula convallis. Duis nunc arcu, ultrices scelerisque dui non, pretium tincidunt diam. Nunc ut rhoncus neque. In hac habitasse platea dictumst.

      Ut ac feugiat turpis. Sed tincidunt, magna non pharetra pellentesque, libero dolor sagittis eros, a mollis elit lectus eu felis. Nam urna tellus, placerat sed enim varius, pulvinar aliquam massa. Aliquam varius hendrerit ullamcorper. Aliquam erat volutpat. Fusce luctus est odio, sed commodo tortor egestas nec. Donec quis laoreet tellus. Quisque ut nisl aliquam, tempus nisl nec, aliquet enim. Nam in lorem id erat aliquam molestie non quis sem. Curabitur id accumsan ex. Maecenas sem orci, lacinia a tortor at, suscipit accumsan ex.
    </p>
  )
}

export function getRowsForTable (rows, currentRows = []) {
  if (currentRows.length === 0) {
    return rows.slice(0, INIT_TABLE_ROW_COUNT)
  } else if (currentRows.length > INIT_TABLE_ROW_COUNT) {
    return rows.slice(0, currentRows.length)
  } else {
    return rows.length >= INIT_TABLE_ROW_COUNT
      ? rows.slice(0, INIT_TABLE_ROW_COUNT)
      : rows.slice(0, rows.length)
  }
}

export function increaseDisplayedTableRows (rows, currentRows = []) {
  return rows.slice(0, currentRows.length + INIT_TABLE_ROW_COUNT)
}
