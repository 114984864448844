import { Navigate, Route, Routes } from 'react-router-dom'
import QualityPage from './pages/quality/QualityPage'
import TasksPage from './pages/tasks/TasksPage'
import CostsPage from './pages/costs/CostsPage'
import CarbonFootprintPage from './pages/carbonFootprint/CarbonFootprintPage'

export function AppPageRoutes() {
  return (
    <Routes>
      <Route path="/quality" element={<QualityPage />} />
      <Route path="/tasks" element={<TasksPage />} />
      <Route path="/costs" element={<CostsPage />} />
      <Route path="/carbon-footprint" element={<CarbonFootprintPage />} />
      <Route exact path="/" element={<Navigate to="/app/quality" />} />
    </Routes>
  )
}

export default AppPageRoutes
