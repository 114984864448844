import "./styles.css";

export const LabelValue = ({ label, value }) => {
  return (
    <div className="label-value">
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};
