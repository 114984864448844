import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import FI from './fi-i18n'
import EN from './en-i18n'

const resources = {
  en: EN,
  fi: FI
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'fi',

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
  })

export default i18n