import './barStyles.css'

export const Bar = ({ green, yellow, red, small = false }) => {
  return (
    <div className={`bar-wrapper${small ? ' small' : ''}`}>
      <div className="bar-green" style={{ width: `${green}%` }}>
        <span>
          {Number.isInteger(Math.round(green)) ? `${Math.round(green)}%` : null}
        </span>
      </div>
      <div className="bar-yellow" style={{ width: `${yellow}%` }} />
      <div className="bar-red" style={{ width: `${red}%` }} />
    </div>
  )
}
