import AppBrand from './AppBrand'

function LoadableContent ({ isLoading = false, withAppBrand = false, children }) {
  const getLoadingOverlay = () => {
    if (isLoading) {
      return (
        <div className='is-overlay'>
          <div className='brand-wrapper p-4'>
            {withAppBrand && <AppBrand />}
          </div>
          <progress className='progress is-small is-primary' max='100' />
        </div>
      )
    } else return null
  }

  return (
    <div className='loadable-content--wrapper'>

      <div className={`loadable-content ${isLoading ? 'is-hidden' : ''}`}>
        {children}
      </div>

      {getLoadingOverlay()}

    </div>
  )
}

export default LoadableContent
