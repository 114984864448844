import { useState } from 'react'
import { getAPIClient, getDataFromResponse, getSitePathParam } from '../helper/axios.client'
import { errorFlag } from '../helper/messages'
import i18n from 'i18next'

function useQualitySummary (customerId = '', siteId = '') {
  const client = getAPIClient('quality/summary/')
  const [data, setData] = useState([])

  const getQualitySummary = async () => {
    const siteIdParam = getSitePathParam(siteId)
    return await client.get(`/${customerId}${siteIdParam}`)
  }

  const reload = async () => {
    try {
      const response = await getQualitySummary()
      const data = getDataFromResponse(response.data.data, 'quality_summary')
      setData(data)
    } catch (e) {
      console.error(e)
      errorFlag(i18n.t('Error fetching data'))
    }
  }

  return { data, reload }
}

export default useQualitySummary
