import "./styles.css";

export const Checkbox = ({ label, checked = false, onChange }) => {
  return (
    <div
      className={`checkbox-wrapper${checked ? " checked" : ""}`}
      onClick={onChange}
    >
      <span className="checkbox-label">{label}</span>
      <div className="checkbox">
        <div className="ball" />
      </div>
    </div>
  );
};
